<template>
  <h1 style="opacity: 1; color: black;">{{ headerText }}</h1>
  <div v-if="editingExisting">
    <h3 style="opacity: 1; color: black;">Currently Editing:</h3>
    <h5 style="opacity: 1; color: black;">{{ oldSchoolVals.schoolName }}</h5>
  </div>
  <div v-if="message['existsMessage']">
    <h4><span>{{ message['existsMessage'] }}</span></h4>
  </div>
  <form @submit.prevent="submitForm">
    <div class="row mb-0 p-0">
      <div class="col-2"></div>
      <div class="col-4">
        <div v-if="isEarlyChildhood">
          <label>Licence Number<span>*</span></label>
          <EarlyChildhoodLicenseComponent
              :tab-idx="1"
              :preselected-value="school.schoolCdn"
              @licenseType="updateLicenceType($event)"/>
        </div>
        <div v-else>
          <label>CDN<span>*</span></label>
          <input tabindex="1" class="form-control" v-model="school.schoolCdn" id="schoolCdn" placeholder="CDN" required
                 :disabled="editingExisting"/>
        </div>
      </div>

      <div class="col-4">
        <ToggleSwitch
          @checked="isEarlyChildhood = !isEarlyChildhood"
          :label-text="`School or Early Childhood Center`"
          />

      </div>
      <div class="col-2"></div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-4">
        <label>Phone Number<span>*</span></label>
        <input tabindex="2" class="form-control" type="text" v-model="school.schoolPhoneNumber" id="schoolPhoneNumber"
               placeholder="Phone Number"
               required :disabled="!showFullForm"/>
      </div>
      <div class="col-4">
        <label>School Name<span>*</span></label>
        <input tabindex="6" class="form-control" type="text" v-model="school.schoolName" id="schoolName"
               placeholder="School Name" required :disabled="!showFullForm"/>

      </div>
      <div class="col-2"></div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-4">
        <label>Fax Number</label>
        <input tabindex="3" class="form-control" type="text" v-model="school.schoolFaxNumber" id="schoolFaxNumber"
               placeholder="Fax" :disabled="!showFullForm"/>
      </div>
      <div class="col-4">
        <label>Primary School Address<span>*</span></label>
        <input tabindex="7" class="form-control" type="text" v-model="school.primarySchoolAddress" id="schoolName"
               placeholder="Primary Address"
               required :disabled="!showFullForm"/>

      </div>
      <div class="col-2"></div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-4">
        <EsuSelectComposition
            :show-district="false"
            :show-school="false"
            :id-esu="(school.idEsu === null && school.idEsu === undefined) ? -1 : school.idEsu"
            :id-district="(school.idDistrict === null && school.idDistrict === undefined) ? -1 : school.idDistrict"
            @selectedDistrict="changeDistrict($event)"
            @selectedEsu="changeEsu($event)"
            :district-disabled="esuAndDistDisabled"
            :school-disabled="editingExisting"
            :esu-disabled="esuAndDistDisabled"
            :tab-idx="4"
        />

      </div>
      <div class="col-4">
        <label>Secondary School Address</label>
        <input tabindex="8" class="form-control" type="text" v-model="school.secondarySchoolAddress" id="schoolName"
               placeholder="Secondary Address (ex. PO BOX)" :disabled="!showFullForm"/>

      </div>
      <div class="col-2"></div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-4">
        <district-select-composition
            :show-school="false"
            :id-esu="(school.idEsu === null && school.idEsu === undefined) ? -1 : school.idEsu"
            :idDistrict="(school.idDistrict === null && school.idDistrict === undefined) ? -1 : school.idDistrict"
            @selectedDistrict="changeDistrict($event)"
            :district-disabled="esuAndDistDisabled"
            :changing-esu="changingEsu"
            :tab-idx="5"
        />
      </div>
      <div class="col-4">
        <label>City<span>*</span></label>
        <input tabindex="9" class="form-control" v-model="school.schoolCity" id="schoolCity" placeholder="City" required
               :disabled="!showFullForm"/>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-4">

      </div>
      <div class="col-4">
        <label>Zip Code<span>*</span></label>
        <input tabindex="10" class="form-control" type="text" v-model="school.schoolZipCode" id="schoolName"
               placeholder="Zip Code" required :disabled="!showFullForm"/>

      </div>
      <div class="col-2"></div>
    </div>

    <button class="button btn-primary" type="submit" :disabled="!canSubmit">{{ submitText }}</button>
  </form>
  <hr/>
  <div v-if="editingExisting" class="container mt-2" style="border: 1px solid black; width: 75%;">
    <div class="row" style="padding: 1%;">
      <h5 style="font-weight: bold; color: black;">Delete School</h5>
      <div class="col-sm" style="text-align: left; margin-top: 30px;">
        <h6>{{ dangerZoneMessage }}</h6>
      </div>
      <div class="col-sm">
        <button :class="canDelete ? `button btn-danger bi-trash-fill` : `button btn-danger bi-trash-fill` "
                :disabled="!canDelete" @click="showDeleteModal = true"> Delete
        </button>
      </div>
    </div>
  </div>
  <div v-if="showDeleteModal">
    <Teleport to="body">
      <OrgDeleteModal
          :org-data="school"
          :show="showDeleteModal"
          :org-type="`School`"
          @close="showDeleteModal = false"
          @deleteCall="deleteHelper"/>
    </Teleport>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {API_URL, ZIP_CODE_REGEX} from "../../../../../Constants";
import axios from "axios";
import EsuSelectComposition from "@/components/LocationSelect/EsuSelectComposition";
import {useStore} from "vuex";
import Swal from "sweetalert2";
import {createCdnInputMask, createPhoneInputMask, numericStringMask} from "@/InputMaskFunctions";
import {isEqual} from "lodash/lang";
import router from "@/router";
import {deleteSchool, getSchool} from "@/Helpers/ApiCalls/SchoolAPICalls";
import OrgDeleteModal from "@/components/Merge/OrgDeleteModal.vue";
import DistrictSelectComposition from "@/components/LocationSelect/DistrictSelectComposition.vue";
import EarlyChildhoodLicenseComponent from "@/components/InstitutionForm/EarlyChildhoodLicenseComponent.vue";
import ToggleSwitch from "@/components/ToggleSwitch/ToggleSwitch.vue";

export default {
  name: "SchoolForm",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToggleSwitch,
    EarlyChildhoodLicenseComponent,
    DistrictSelectComposition,
    EsuSelectComposition,
    OrgDeleteModal,
  },
  props: {
    headerText: String,
    idSchool: Number,
    editingExisting: Boolean,
    submitText: String
  },
  setup(props, context) {
    const store = useStore();
    const authToken = computed(() => store.getters['auth/authenticated']);
    let school = ref({});
    let oldSchoolVals = ref({});
    let exists = ref(false);
    let message = ref({});
    let isLoaded = ref(false);
    const showDeleteModal = ref(false);
    const isEarlyChildhood = ref(false);

    onBeforeMount(() => {
      if (props.idSchool !== undefined && props.idSchool !== null) {
        getSchoolData();
      }
      if (!props.editingExisting) {
        school.value.idDistrict = -1;
        school.value.idEsu = -1;
      }
      isLoaded.value = true;
    })

    watch(() => school.value.schoolZipCode, () => {
      if (!ZIP_CODE_REGEX.test(school.value.schoolZipCode)) {
        school.value.schoolZipCode = school.value.schoolZipCode.substring(0, school.value.schoolZipCode.length - 1);
      }
      if (school.value.schoolZipCode.length > 5) {
        school.value.schoolZipCode = school.value.schoolZipCode.substring(0, school.value.schoolZipCode.length - 1);
      }
    })

    watch(() => school.value.schoolCdn, () => {
      if (!isEarlyChildhood.value) {
        const numeric = school.value.schoolCdn.replaceAll(/\D/g, '');
        let mask = createCdnInputMask(numeric);
        school.value.schoolCdn = numericStringMask(numeric, mask);
      }
    })

    watch(() => school.value.schoolPhoneNumber, () => {
      const numeric = school.value.schoolPhoneNumber.replaceAll(/[A-Za-z]/g, '');
      let mask = createPhoneInputMask(numeric);
      school.value.schoolPhoneNumber = numericStringMask(numeric, mask);
    })

    watch(() => school.value.schoolFaxNumber, () => {
      const numeric = school.value.schoolFaxNumber.replaceAll(/\D/g, '');
      let mask = createPhoneInputMask(numeric);
      school.value.schoolFaxNumber = numericStringMask(numeric, mask);
    })

    watch(() => isEarlyChildhood.value, () => {
      school.value.schoolCdn = "";
    })

    const canSubmit = computed(() => {
      let valid_zip = ZIP_CODE_REGEX.test(school.value.schoolZipCode) && school.value.schoolZipCode.length === 5;
      let valid_name = school.value.schoolName !== "";
      let valid_address = school.value.primarySchoolAddress !== "";
      let valid_phone = school.value.schoolPhoneNumber !== "" && school.value.schoolPhoneNumber !== undefined
          && school.value.schoolPhoneNumber !== null;
      let valid_city = school.value.schoolCity !== "";
      let valid_esu = school.value.idEsu !== null && school.value.idEsu !== undefined && school.value.idEsu !== -1;
      let valid_district = school.value.idDistrict !== null && school.value.idDistrict !== undefined && school.value.idDistrict !== -1;
      let valid_phone_length = false;

      if (valid_phone) {
        valid_phone_length = school.value.schoolPhoneNumber.length === 14;
      }

      let ret_val = false;

      if (!props.editingExisting) {

        ret_val = valid_zip && valid_name && valid_address && valid_phone_length && valid_city && valid_esu && valid_district;
      } else {
        ret_val = !isEqual(school.value, oldSchoolVals.value) && valid_zip && valid_address && valid_name && valid_esu && valid_district;
      }
      return ret_val;
    })

    async function getSchoolData() {
      await axios.get(getSchool(props.idSchool), {
        params: {
          showUserCount: true
        }
      }).then((result) => {
        school.value = result.data;
        oldSchoolVals.value = {...school.value};
      })
    }

    const schoolExists = () => {
      let get_uri = API_URL + "/school/exists?_schoolCdn=" + school.value.schoolCdn;
      axios.get(get_uri)
          .then((result) => {
            exists.value = result.data.objectExists;
            message.value["existsMessage"] = !props.editingExisting ? result.data.message : null;
          })

      if (props.editingExisting === true) {
        delete message.value["existsMessage"]
      }

      if (!exists.value) {
        delete message.value["existsMessage"];
      }
    }

    const showFullForm = computed(() => {
      let valid_cdn_length = false;

      if (!isEarlyChildhood.value) {
        if (school.value.schoolCdn) {
          valid_cdn_length = school.value.schoolCdn.length === 11;
          if (valid_cdn_length) {
            schoolExists();
          } else {
            delete message.value["existsMessage"];
          }
        }
      } else {
        valid_cdn_length = true;
      }
      return valid_cdn_length && (!exists.value || props.editingExisting);
    })

    function changeDistrict(event) {
      school.value.idDistrict = parseInt(event);
    }

    function changeEsu(event) {
      school.value.idEsu = parseInt(event);
    }

    const submitForm = () => {
      let post_body = {
        idSchool: props.idSchool,
        idDistrict: school.value.idDistrict,
        schoolName: school.value.schoolName,
        primarySchoolAddress: school.value.primarySchoolAddress,
        secondarySchoolAddress: school.value.secondarySchoolAddress,
        schoolPhoneNumber: school.value.schoolPhoneNumber,
        schoolZipCode: school.value.schoolZipCode,
        schoolCity: school.value.schoolCity,
        schoolCdn: school.value.schoolCdn,
        schoolFaxNumber: school.value.schoolFaxNumber
      }
      if (!props.editingExisting) {
        createSchool(post_body);
      } else {
        updateSchool(post_body);
      }
    }

    async function createSchool(postBody) {
      let post_uri = API_URL + "/school/create";
      await axios.post(post_uri, postBody, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        Swal.fire({
          title: "Created School",
          text: "Successfully Created School With Name: " + school.value.schoolName,
          icon: 'success'
        }).then(function () {
          location.reload();
        })
      })
    }

    async function updateSchool(postBody) {
      let post_uri = API_URL + "/school/update";
      await axios.post(post_uri, postBody, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        Swal.fire({
          title: "Updated School",
          text: "Successfully Updated: " + school.value.schoolName,
          icon: 'success'
        }).then(function () {
          location.reload();
        })
      })
    }

    const routeToMerge = () => {
      router.push({
        name: 'OrganizationMerge', params: {
          idRemovingSchool: props.idSchool,
          idEsu: school.value.idEsu, idDistrict: school.value.idDistrict
        }
      })
    }

    const canDelete = computed(() => {
      return school.value.userCount === 0;
    })

    function deleteHelper() {
      Swal.fire({
        title: 'Are You Sure?',
        text: `You Will Delete ${school.value.schoolName} and future users will not be able to register under this school`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          deleteSchoolCall();
        }
      })
    }

    async function deleteSchoolCall() {
      await axios.delete(deleteSchool(props.idSchool), {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        if (result.status === 200) {
          router.push({name: 'OrganizationList'})
        }
      })
    }

    function updateLicenceType(event) {
      school.value.schoolCdn = event;
    }


    const dangerZoneMessage = computed(() => {
      return canDelete.value ? `Deleting a school is an irreversible action. Users will not be able to register for Project Para under this school.`
          : `There users linked to this school.
          You can transfer the users or merge the school,
          but this school cannot be deleted. Merging a school is an irreversible action. `
    })

    const esuAndDistDisabled = computed(() => {
      if (props.editingExisting) {
        return false;
      }
      return !showFullForm.value;
    })

    const changingEsu = computed(() => {
      return oldSchoolVals.value.idEsu !== school.value.idEsu;
    })

    return {
      school,
      message,
      showFullForm,
      canSubmit,
      oldSchoolVals,
      isLoaded,
      canDelete,
      dangerZoneMessage,
      showDeleteModal,
      esuAndDistDisabled,
      changingEsu,
      isEarlyChildhood,
      routeToMerge,
      submitForm,
      changeDistrict,
      changeEsu,
      deleteHelper,
      updateLicenceType,
    }
  }
}
</script>

<style scoped>
span {
  color: red;
}

input {
  height: 41px;
}
</style>