<template>
  <div class="row">
    <div class="col-8">
      <select class="form-select" :tabindex="tabIdx" v-model="selectedLicenseType"
              @change="updateLicenseType"
              :disabled="selectValDisabled"
              style="height: 41px;">
        <option v-for="(val, idx) in licenseTypeArray" :key="idx" :value="val.licenceVal">
          {{ val.licenceVal !== "0" ? val.licenceVal : "" }} ({{ val.description }})
        </option>
      </select>
    </div>
    <div class="col">
      <input class="form-control" :tabindex="tabIdx + 1" v-model="licenceNumber" style="height: 41px"
             @input="updateLicenseType"/>
    </div>
  </div>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";

export default {
  name: "EarlyChildhoodLicenseComponent",
  props: {
    tabIdx: {
      type: Number,
      default: 1,
    },
    preselectedValue: {
      type: String,
      default: "",
    },
    selectValDisabled: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, context) {
    const licenseTypeArray = ref([
      {licenceVal: "0", description: "Select a Licence Type"},
      {licenceVal: "FI", description: "Family Childcare Home I"},
      {licenceVal: "FII", description: "Family Childcare Home II"},
      {licenceVal: "CCC", description: "Child Care Center"},
      {licenceVal: "PRE", description: "Preschool"},
      {licenceVal: "SAOC", description: "School-Age Only Center"},
    ]);

    const licenseNumber = ref("");
    const selectedLicenseType = ref("0");

    watch(() => licenseNumber.value, () => {
      licenseNumber.value = licenseNumber.value.replaceAll(/[A-Za-z]/g, '');
      let max_length = selectedLicenseType.value === "FI" ? 5 : 4;
      if (licenseNumber.value.length > max_length) {
        licenseNumber.value = licenseNumber.value.substring(0, max_length);
      }
    })

    function updateLicenseType() {
      context.emit("licenseType", `${selectedLicenseType.value}${licenseNumber.value}`);
    }

    onBeforeMount(() => {
      if (props.preselectedValue !== "") {
        const splitProp = props.preselectedValue.split(/(?<alpha>[A-Za-z]*)(?<numeric>[0-9]*)/);
        selectedLicenseType.value = splitProp[0];
        licenseNumber.value = splitProp[1];
      }
    })

    return {
      licenseTypeArray,
      licenceNumber: licenseNumber,
      selectedLicenseType,
      updateLicenseType,
    }

  },
};
</script>

<style scoped>
</style>